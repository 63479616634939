<template>
    <div class="scan-page">
        <scanner @barcodeHasBeenScanned="barcodeScanned($event)"></scanner>

        <!-- Manual entry -->
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <label>Manually enter the IMEI</label>
                </div>
                <div class="col">
                    <div class="form-group mb-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fa fa-search mb-0"></i></span>
                            </div>
                            <input type="text" inputmode="numeric" pattern="[0-9]*" class="form-control" placeholder="Enter IMEI" v-on:keyup.enter="validateImei()" v-model="imei">
                            <span class="input-clear" v-if="imei" @click="clearImei()"><i class="fas fa-times-circle mb-0"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-auto pl-0">
                    <button class="btn btn-success btn-block d-md-inline-block py-2" @click="validateImei()" :disabled="!imei || validating">
                        <span class="d-md-none"><i class="fa fa-search mr-0"></i></span>
                        <span class="d-none d-md-inline">Search</span>
                    </button>
                </div>
            </div>

            <!-- Validating overlay -->
            <loading-state
                    v-if="validating && !invalidImei"
                    overlay
                    title="Validating IMEI">
            </loading-state>

            <!-- Invalid IMEI -->
            <empty-state
                    v-if="imei && invalidImei"
                    statusIcon="error"
                    :title="`${ imei } <span class='font-weight-light'>is either invalid or the asset has not yet been processed. Try scanning again or check that the asset exists in the admin panel.</span>`">
            </empty-state>
            <hr />
            <div v-if="assetList.length">
                <div class="row">
                    <div class="col-12">
                        <label>Currently held assets</label>
                    </div>
                </div>
                <div class="row" v-for="asset in assetList" :key="asset.id">
                    <div class="col-12">
                        <p>{{ asset.reference }} - {{ asset.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {
        props:['cannotRepairAsset', 'diagnosticsComplete'],

        data() {
            return {
                imei: null,
                invalidImei: false,
                passedDiagnosis: false,
                validating: false,
                validImei: false,
                assetList: [],
            }
        },

        computed: {
            ...mapGetters([
                "asset",
            ])
        },

        watch: {
            imei: function() {
                this.invalidImei = false;
                this.passedDiagnosis = false;
            }
        },

        mounted() {
            this.clearAsset();
            this.refreshAssets();
        },

        methods: {
            ...mapActions([
                "clearAsset",
                "displayToast",
                "setAssetStatus",
                "getAssetByStatus"
            ]),

            /** Barcode scanned */
            barcodeScanned(barcode) {
                this.imei = barcode.data;
                this.validateImei();
            },

            /** Clear the input */
            clearImei() {
                this.imei = null;
                this.validImei = false;
            },

            /** Validate the IMEI */
            validateImei() {
                this.setAssetStatus({assetReference: this.imei, status: 'technician-received-pending-repair'}).then(() => {
                    this.imei = null;
                    this.validImei = true;
                    this.displayToast({text: "Device logged as received"});
                    this.refreshAssets();
                }).catch((error) => {
                    this.invalidImei = true;
                    this.passedDiagnosis = false;
                    this.validImei = false;
                    this.displayToast({text: "Error with logging asset: " + error, type: 'error'})
                });
            },

            refreshAssets() {
                this.getAssetByStatus('technician-received-pending-repair').then(response => {
                    this.assetList = response;
                });
            },

            goToJob() {
                this.$router.push({name: `job`, params: {jobId: this.asset.job_id}});
            }
        }
    }
</script>

<style scoped>

</style>